@mixin bg-opacity($color, $opacity: 1) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

// Progress bars

@mixin progress-variant($color) {
    background-color: $color;
    &[value]::-webkit-progress-value {
        background-color: $color;
    }

    &[value]::-moz-progress-bar {
        background-color: $color;
    }

    // IE10+, Microsoft Edge
    &[value]::-ms-fill {
        background-color: $color;
    }

    // IE9
    @media screen and (min-width: 0\0) {
        .progress-bar {
            background-color: $color;
        }
    }
}
