.modal {
	&__header {
		height: 75px;
	}
	
	&__footer {
	
	}
	
	&__body {
	
	}
}

.modal-remove {

	&__question {
		height: 80px;
		text-align: center;
	}

	&__buttons {
		display: flex;
		justify-content: space-around;
	}

	button {
		min-width: 75px;
	}

}


