$white-color: (
    'base': #ffffff,
);
$black-color: (
    'base': #000000,
);
$primary: (
    'lighten-5': #e0f3f4,
    'lighten-4': #b3e2e3,
    'lighten-3': #80ced0,
    'lighten-2': #4dbabd,
    'lighten-1': #26acae,
    'base': #009da0,
    'darken-1': #009598,
    'darken-2': #008b8e,
    'darken-3': #008184,
    'darken-4': #006f73,
    'accent-1': #a1fbff,
    'accent-2': #6ef9ff,
    'accent-3': #3bf7ff,
    'accent-4': #22f6ff,
);
$success: (
    'lighten-5': #e2f8f0,
    'lighten-4': #b6edd8,
    'lighten-3': #86e1bf,
    'lighten-2': #55d4a5,
    'lighten-1': #30cb91,
    'base': #0cc27e,
    'darken-1': #0abc76,
    'darken-2': #08b46b,
    'darken-3': #06ac61,
    'darken-4': #039f4e,
    'accent-1': #c9ffe0,
    'accent-2': #96ffc3,
    'accent-3': #63ffa5,
    'accent-4': #4aff96,
);
$info: (
    'lighten-5': #e4f7fa,
    'lighten-4': #bbebf3,
    'lighten-3': #8edeec,
    'lighten-2': #60d0e4,
    'lighten-1': #3ec6de,
    'base': #1cbcd8,
    'darken-1': #19b6d4,
    'darken-2': #14adce,
    'darken-3': #11a5c8,
    'darken-4': #0997bf,
    'accent-1': #eafaff,
    'accent-2': #b7edff,
    'accent-3': #84e1ff,
    'accent-4': #6adaff,
);
$warning: (
    'lighten-5': #fff1ec,
    'lighten-4': #ffddcf,
    'lighten-3': #ffc6b0,
    'lighten-2': #ffaf90,
    'lighten-1': #ff9e78,
    'base': #ff8d60,
    'darken-1': #ff8558,
    'darken-2': #ff7a4e,
    'darken-3': #ff7044,
    'darken-4': #ff5d33,
    'accent-1': #ffffff,
    'accent-2': #ffefec,
    'accent-3': #ffe4de,
    'accent-4': #ffcfc4,
);
$danger: (
    'lighten-5': #ffebed,
    'lighten-4': #ffcdd3,
    'lighten-3': #ffacb5,
    'lighten-2': #ff8a97,
    'lighten-1': #ff7181,
    'base': #ff586b,
    'darken-1': #ff5063,
    'darken-2': #ff4758,
    'darken-3': #ff3d4e,
    'darken-4': #ff2d3c,
    'accent-1': #ffffff,
    'accent-2': #fbebed,
    'accent-3': #ffd9db,
    'accent-4': #ffbfc3,
);
$red-color: (
    'lighten-5': #ffebee,
    'lighten-4': #ffcdd2,
    'lighten-3': #ef9a9a,
    'lighten-2': #e57373,
    'lighten-1': #ef5350,
    'base': #f44336,
    'darken-1': #e53935,
    'darken-2': #d32f2f,
    'darken-3': #c62828,
    'darken-4': #b71c1c,
    'accent-1': #ff8a80,
    'accent-2': #ff5252,
    'accent-3': #ff1744,
    'accent-4': #d50000,
);
$pink: (
    'lighten-5': #fce4ec,
    'lighten-4': #f8bbd0,
    'lighten-3': #f48fb1,
    'lighten-2': #f06292,
    'lighten-1': #ec407a,
    'base': #e91e63,
    'darken-1': #d81b60,
    'darken-2': #c2185b,
    'darken-3': #ad1457,
    'darken-4': #880e4f,
    'accent-1': #ff80ab,
    'accent-2': #ff4081,
    'accent-3': #f50057,
    'accent-4': #c51162,
);
$purple: (
    'lighten-5': #f3e5f5,
    'lighten-4': #e1bee7,
    'lighten-3': #ce93d8,
    'lighten-2': #ba68c8,
    'lighten-1': #ab47bc,
    'base': #9c27b0,
    'darken-1': #8e24aa,
    'darken-2': #7b1fa2,
    'darken-3': #6a1b9a,
    'darken-4': #4a148c,
    'accent-1': #ea80fc,
    'accent-2': #e040fb,
    'accent-3': #d500f9,
    'accent-4': #a0f,
);
$deep-purple: (
    'lighten-5': #ede7f6,
    'lighten-4': #d1c4e9,
    'lighten-3': #b39ddb,
    'lighten-2': #9575cd,
    'lighten-1': #7e57c2,
    'base': #673ab7,
    'darken-1': #5e35b1,
    'darken-2': #512da8,
    'darken-3': #4527a0,
    'darken-4': #311b92,
    'accent-1': #b388ff,
    'accent-2': #7c4dff,
    'accent-3': #651fff,
    'accent-4': #6200ea,
);
$indigo: (
    'lighten-5': #e8eaf6,
    'lighten-4': #c5cae9,
    'lighten-3': #9fa8da,
    'lighten-2': #7986cb,
    'lighten-1': #5c6bc0,
    'base': #3f51b5,
    'darken-1': #3949ab,
    'darken-2': #303f9f,
    'darken-3': #283593,
    'darken-4': #1a237e,
    'accent-1': #8c9eff,
    'accent-2': #536dfe,
    'accent-3': #3d5afe,
    'accent-4': #304ffe,
);
$blue: (
    'lighten-5': #e3f2fd,
    'lighten-4': #bbdefb,
    'lighten-3': #90caf9,
    'lighten-2': #64b5f6,
    'lighten-1': #42a5f5,
    'base': #2196f3,
    'darken-1': #1e88e5,
    'darken-2': #1976d2,
    'darken-3': #1565c0,
    'darken-4': #0d47a1,
    'accent-1': #82b1ff,
    'accent-2': #448aff,
    'accent-3': #2979ff,
    'accent-4': #2962ff,
);
$light-blue: (
    'lighten-5': #e1f5fe,
    'lighten-4': #b3e5fc,
    'lighten-3': #81d4fa,
    'lighten-2': #4fc3f7,
    'lighten-1': #29b6f6,
    'base': #03a9f4,
    'darken-1': #039be5,
    'darken-2': #0288d1,
    'darken-3': #0277bd,
    'darken-4': #01579b,
    'accent-1': #80d8ff,
    'accent-2': #40c4ff,
    'accent-3': #00b0ff,
    'accent-4': #0091ea,
);
$cyan: (
    'lighten-5': #e0f7fa,
    'lighten-4': #b2ebf2,
    'lighten-3': #80deea,
    'lighten-2': #4dd0e1,
    'lighten-1': #26c6da,
    'base': #00bcd4,
    'darken-1': #00acc1,
    'darken-2': #0097a7,
    'darken-3': #00838f,
    'darken-4': #006064,
    'accent-1': #84ffff,
    'accent-2': #18ffff,
    'accent-3': #00e5ff,
    'accent-4': #00b8d4,
);
$teal: (
    'lighten-5': #e0f2f1,
    'lighten-4': #b2dfdb,
    'lighten-3': #80cbc4,
    'lighten-2': #4db6ac,
    'lighten-1': #26a69a,
    'base': #009688,
    'darken-1': #00897b,
    'darken-2': #00796b,
    'darken-3': #00695c,
    'darken-4': #004d40,
    'accent-1': #a7ffeb,
    'accent-2': #64ffda,
    'accent-3': #1de9b6,
    'accent-4': #00bfa5,
);
$green: (
    'lighten-5': #e8f5e9,
    'lighten-4': #c8e6c9,
    'lighten-3': #a5d6a7,
    'lighten-2': #81c784,
    'lighten-1': #66bb6a,
    'base': #4caf50,
    'darken-1': #43a047,
    'darken-2': #388e3c,
    'darken-3': #2e7d32,
    'darken-4': #1b5e20,
    'accent-1': #b9f6ca,
    'accent-2': #69f0ae,
    'accent-3': #00e676,
    'accent-4': #00c853,
);
$light-green: (
    'lighten-5': #f1f8e9,
    'lighten-4': #dcedc8,
    'lighten-3': #c5e1a5,
    'lighten-2': #aed581,
    'lighten-1': #9ccc65,
    'base': #8bc34a,
    'darken-1': #7cb342,
    'darken-2': #689f38,
    'darken-3': #558b2f,
    'darken-4': #33691e,
    'accent-1': #ccff90,
    'accent-2': #b2ff59,
    'accent-3': #76ff03,
    'accent-4': #64dd17,
);
$lime: (
    'lighten-5': #f9fbe7,
    'lighten-4': #f0f4c3,
    'lighten-3': #e6ee9c,
    'lighten-2': #dce775,
    'lighten-1': #d4e157,
    'base': #cddc39,
    'darken-1': #c0ca33,
    'darken-2': #afb42b,
    'darken-3': #9e9d24,
    'darken-4': #827717,
    'accent-1': #f4ff81,
    'accent-2': #eeff41,
    'accent-3': #c6ff00,
    'accent-4': #aeea00,
);
$yellow: (
    'lighten-5': #fffde7,
    'lighten-4': #fff9c4,
    'lighten-3': #fff59d,
    'lighten-2': #fff176,
    'lighten-1': #ffee58,
    'base': #ffeb3b,
    'darken-1': #fdd835,
    'darken-2': #fbc02d,
    'darken-3': #f9a825,
    'darken-4': #f57f17,
    'accent-1': #ffff8d,
    'accent-2': #ff0,
    'accent-3': #ffea00,
    'accent-4': #ffd600,
);
$amber: (
    'lighten-5': #fff8e1,
    'lighten-4': #ffecb3,
    'lighten-3': #ffe082,
    'lighten-2': #ffd54f,
    'lighten-1': #ffca28,
    'base': #ffc107,
    'darken-1': #ffb300,
    'darken-2': #ffa000,
    'darken-3': #ff8f00,
    'darken-4': #ff6f00,
    'accent-1': #ffe57f,
    'accent-2': #ffd740,
    'accent-3': #ffc400,
    'accent-4': #ffab00,
);
$orange: (
    'lighten-5': #fff3e0,
    'lighten-4': #ffe0b2,
    'lighten-3': #ffcc80,
    'lighten-2': #ffb74d,
    'lighten-1': #ffa726,
    'base': #ff9800,
    'darken-1': #fb8c00,
    'darken-2': #f57c00,
    'darken-3': #ef6c00,
    'darken-4': #e65100,
    'accent-1': #ffd180,
    'accent-2': #ffab40,
    'accent-3': #ff9100,
    'accent-4': #ff6d00,
);
$deep-orange: (
    'lighten-5': #fbe9e7,
    'lighten-4': #ffccbc,
    'lighten-3': #ffab91,
    'lighten-2': #ff8a65,
    'lighten-1': #ff7043,
    'base': #ff5722,
    'darken-1': #f4511e,
    'darken-2': #e64a19,
    'darken-3': #d84315,
    'darken-4': #bf360c,
    'accent-1': #ff9e80,
    'accent-2': #ff6e40,
    'accent-3': #ff3d00,
    'accent-4': #dd2c00,
);
$brown: (
    'lighten-5': #efebe9,
    'lighten-4': #d7ccc8,
    'lighten-3': #bcaaa4,
    'lighten-2': #a1887f,
    'lighten-1': #8d6e63,
    'base': #795548,
    'darken-1': #6d4c41,
    'darken-2': #5d4037,
    'darken-3': #4e342e,
    'darken-4': #3e2723,
    'accent-1': #d7ccc8,
    'accent-2': #bcaaa4,
    'accent-3': #8d6e63,
    'accent-4': #5d4037,
);
$grey: (
    'lighten-5': #fafafa,
    'lighten-4': #f5f5f5,
    'lighten-3': #eee,
    'lighten-2': #e0e0e0,
    'lighten-1': #bdbdbd,
    'base': #9e9e9e,
    'darken-1': #757575,
    'darken-2': #616161,
    'darken-3': #424242,
    'darken-4': #212121,
    'accent-1': #f5f5f5,
    'accent-2': #eee,
    'accent-3': #bdbdbd,
    'accent-4': #616161,
);
$blue-grey: (
    'lighten-5': #eceff1,
    'lighten-4': #cfd8dc,
    'lighten-3': #b0bec5,
    'lighten-2': #90a4ae,
    'lighten-1': #78909c,
    'base': #607d8b,
    'darken-1': #546e7a,
    'darken-2': #455a64,
    'darken-3': #37474f,
    'darken-4': #263238,
    'accent-1': #cfd8dc,
    'accent-2': #b0bec5,
    'accent-3': #78909c,
    'accent-4': #455a64,
);

$shades: (
    'black': #000000,
    'white': #ffffff,
    'transparent': transparent,
);
$colors: (
    'white': $white-color,
    'black': $black-color,
    'primary': $primary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'red': $red-color,
    'pink': $pink,
    'purple': $purple,
    'deep-purple': $deep-purple,
    'indigo': $indigo,
    'blue': $blue,
    'light-blue': $light-blue,
    'cyan': $cyan,
    'teal': $teal,
    'green': $green,
    'light-green': $light-green,
    'lime': $lime,
    'yellow': $yellow,
    'amber': $amber,
    'orange': $orange,
    'deep-orange': $deep-orange,
    'brown': $brown,
    'grey': $grey,
    'blue-grey': $blue-grey,
    'shades': $shades,
);

$gradient-colors: (
    gradient-indigo-light-blue: (
        'start-color': map-get($indigo, lighten-4),
        'end-color': map-get($light-blue, lighten-4),
    ),
    gradient-light-blue-indigo: (
        'start-color': map-get($light-blue, lighten-4),
        'end-color': map-get($indigo, lighten-3),
    ),
    gradient-yellow-green: (
        'start-color': map-get($yellow, accent-1),
        'end-color': map-get($green, accent-1),
    ),
    gradient-orange-deep-orange: (
        'start-color': map-get($orange, lighten-4),
        'end-color': map-get($deep-orange, lighten-4),
    ),
    gradient-deep-purple-purple: (
        'start-color': map-get($deep-purple, lighten-4),
        'end-color': map-get($purple, lighten-5),
    ),
    gradient-red-pink: (
        'start-color': map-get($red-color, accent-2),
        'end-color': map-get($pink, lighten-3),
    ),
    gradient-light-green-amber: (
        'start-color': map-get($light-green, lighten-3),
        'end-color': map-get($amber, lighten-5),
    ),
    gradient-amber-amber: (
        'start-color': map-get($amber, darken-4),
        'end-color': map-get($amber, lighten-1),
    ),
    gradient-purple-pink: (
        'start-color': map-get($purple, accent-1),
        'end-color': map-get($pink, lighten-5),
    ),
    gradient-indigo-dark-blue: (
        'start-color': map-get($indigo, darken-1),
        'end-color': map-get($light-blue, lighten-2),
    ),
    gradient-teal-cyan: (
        'start-color': map-get($teal, lighten-5),
        'end-color': map-get($cyan, accent-3),
    ),
    gradient-blue-grey-blue-grey: (
        'start-color': map-get($blue-grey, lighten-4),
        'end-color': map-get($blue-grey, darken-1),
    ),
    gradient-cyan-dark-green: (
        'start-color': map-get($cyan, darken-2),
        'end-color': map-get($light-green, accent-2),
    ),
    gradient-orange-amber: (
        'start-color': map-get($orange, darken-4),
        'end-color': map-get($amber, darken-2),
    ),
    gradient-indigo-blue: (
        'start-color': map-get($indigo, darken-2),
        'end-color': map-get($blue, darken-2),
    ),
    gradient-brown-brown: (
        'start-color': map-get($brown, darken-1),
        'end-color': map-get($brown, lighten-4),
    ),
    gradient-blue-grey-blue: (
        'start-color': map-get($blue-grey, darken-4),
        'end-color': map-get($blue, accent-3),
    ),
    gradient-purple-deep-orange: (
        'start-color': map-get($purple, darken-1),
        'end-color': map-get($deep-orange, accent-2),
    ),
    gradient-green-teal: (
        'start-color': map-get($green, darken-1),
        'end-color': map-get($teal, accent-3),
    ),
    gradient-purple-light-blue: (
        'start-color': map-get($purple, accent-2),
        'end-color': map-get($light-blue, lighten-2),
    ),
    gradient-cyan-cyan: (
        'start-color': map-get($cyan, accent-2),
        'end-color': map-get($cyan, accent-3),
    ),
    gradient-yellow-teal: (
        'start-color': map-get($yellow, lighten-4),
        'end-color': map-get($teal, accent-2),
    ),
    gradient-purple-deep-purple: (
        'start-color': map-get($purple, darken-2),
        'end-color': map-get($deep-purple, accent-2),
    ),
    gradient-cyan-light-green: (
        'start-color': map-get($cyan, accent-3),
        'end-color': map-get($light-green, accent-1),
    ),
    gradient-purple-amber: (
        'start-color': map-get($purple, accent-3),
        'end-color': map-get($amber, darken-2),
    ),
    gradient-indigo-purple: (
        'start-color': map-get($indigo, darken-2),
        'end-color': map-get($purple, darken-2),
    ),
    gradient-deep-purple-blue: (
        'start-color': map-get($deep-purple, accent-4),
        'end-color': map-get($blue, darken-2),
    ),
    gradient-deep-orange-orange: (
        'start-color': map-get($deep-orange, darken-4),
        'end-color': map-get($orange, darken-2),
    ),
    gradient-light-blue-cyan: (
        'start-color': map-get($light-blue, darken-2),
        'end-color': map-get($cyan, lighten-1),
    ),
    gradient-blue-indigo: (
        'start-color': map-get($blue, accent-4),
        'end-color': map-get($indigo, darken-1),
    ),
    gradient-strawberry: (
        'start-color': #fe0b46,
        'end-color': #ffab96,
    ),
    gradient-blueberry: (
        'start-color': #6078ea,
        'end-color': #17ead9,
    ),
    gradient-mint: (
        'start-color': #23bcbb,
        'end-color': #45e994,
    ),
    gradient-orange: (
        'start-color': #c471f3,
        'end-color': #f671cd,
    ),
    gradient-pomegranate: (
        'start-color': #9b3cb7,
        'end-color': #ff396f,
    ),
    gradient-green-tea: (
        'start-color': #004b91,
        'end-color': #78cc37,
    ),
    gradient-blackberry: (
        'start-color': #843cf7,
        'end-color': #38b8f2,
    ),
    gradient-plum: (
        'start-color': #e35c67,
        'end-color': #381ce2,
    ),
    gradient-passion-fruit: (
        'start-color': #8137f7,
        'end-color': #f6ab3e,
    ),
    gradient-sublime-vivid: (
        'start-color': #fc466b,
        'end-color': #3f5efb,
    ),
    gradient-king-yna: (
        'start-color': #1a2a6c,
        'end-color': #b21f1f,
    ),
    gradient-summer: (
        'start-color': #22c1c3,
        'end-color': #fdbb2d,
    ),
    gradient-crystal-clear: (
        'start-color': #159957,
        'end-color': #155799,
    ),
    gradient-celestial: (
        'start-color': #c33764,
        'end-color': #1d2671,
    ),
    gradient-mini: (
        'start-color': #30e8bf,
        'end-color': #ff8235,
    ),
    gradient-ibiza-sunset: (
        'start-color': #ee0979,
        'end-color': #ff6a00,
    ),
    gradient-dawn: (
        'start-color': #f3904f,
        'end-color': #3b4371,
    ),
    gradient-brady-brady-fun-fun: (
        'start-color': #00c3ff,
        'end-color': #ffff1c,
    ),
    gradient-cosmic-fusion: (
        'start-color': #ff00cc,
        'end-color': #333399,
    ),
    gradient-nepal: (
        'start-color': #de6161,
        'end-color': #2657eb,
    ),
    gradient-love-couple: (
        'start-color': #3a6186,
        'end-color': #89253e,
    ),
    gradient-sunset: (
        'start-color': #0b486b,
        'end-color': #f56217,
    ),
    gradient-grapefruit-sunset: (
        'start-color': #e96443,
        'end-color': #904e95,
    ),
    gradient-politics: (
        'start-color': #2196f3,
        'end-color': #f44336,
    ),
    gradient-red-ocean: (
        'start-color': #1d4350,
        'end-color': #a43931,
    ),
    gradient-back-to-earth: (
        'start-color': #20cae4,
        'end-color': #1ce5b7,
    ),
    gradient-flickr: (
        'start-color': #33001b,
        'end-color': #ff0084,
    ),
    gradient-virgin-america: (
        'start-color': #7b4397,
        'end-color': #dc2430,
    ),
    gradient-purple-bliss: (
        'start-color': #360033,
        'end-color': #0b8793,
    ),
    gradient-man-of-steel: (
        'start-color': #780206,
        'end-color': #061161,
    ),
    gradient-shdow-night: (
        'start-color': #000000,
        'end-color': #53346d,
    ),
    gradient-dracula: (
        'start-color': #dc2424,
        'end-color': #4a569d,
    ),
    gradient-bloody-mary: (
        'start-color': #ff512f,
        'end-color': #dd2476,
    ),
    gradient-harmonic-energy: (
        'start-color': #16a085,
        'end-color': #f4d03f,
    ),
    gradient-purple-love: (
        'start-color': #cc2b5e,
        'end-color': #753a88,
    ),
);
