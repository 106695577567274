// User select
// For selecting text on the page

@mixin user-select($select) {
    -webkit-user-select: $select;
    -moz-user-select: $select;
    -ms-user-select: $select; // IE10+
    user-select: $select;
}

@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
    box-shadow: $shadow;
}

// Box sizing
@mixin box-sizing($boxmodel) {
    -webkit-box-sizing: $boxmodel;
    -moz-box-sizing: $boxmodel;
    box-sizing: $boxmodel;
}

@mixin transition($time, $type) {
    -webkit-transition: all $time $type;
    -moz-transition: all $time $type;
    -o-transition: all $time $type;
    -ms-transition: all $time $type;
    transition: all $time $type;
}

@mixin transform-scale($value) {
    -webkit-transform: scale($value);
    -moz-transform: scale($value);
    -o-transform: scale($value);
    -ms-transform: scale($value);
    transform: scale($value);
}

@mixin transform-translate-x($value) {
    -webkit-transform: translate3d($value, 0, 0);
    -moz-transform: translate3d($value, 0, 0);
    -o-transform: translate3d($value, 0, 0);
    -ms-transform: translate3d($value, 0, 0);
    transform: translate3d($value, 0, 0);
}

@mixin transform-origin($coordinates) {
    -webkit-transform-origin: $coordinates;
    -moz-transform-origin: $coordinates;
    -o-transform-origin: $coordinates;
    -ms-transform-origin: $coordinates;
    transform-origin: $coordinates;
}

@mixin set-background-color-button($color) {
    .moving-tab {
        background-color: $color;
    }
}

@mixin radial-gradient($extern-color, $center-color) {
    background: $extern-color;
    background: -moz-radial-gradient(
        center,
        ellipse cover,
        $center-color 0%,
        $extern-color 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
        radial,
        center center,
        0px,
        center center,
        100%,
        color-stop(0%, $center-color),
        color-stop(100%, $extern-color)
    ); /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(
        center,
        ellipse cover,
        $center-color 0%,
        $extern-color 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(
        center,
        ellipse cover,
        $center-color 0%,
        $extern-color 100%
    ); /* Opera 12+ */
    background: -ms-radial-gradient(
        center,
        ellipse cover,
        $center-color 0%,
        $extern-color 100%
    ); /* IE10+ */
    background: radial-gradient(
        ellipse at center,
        $center-color 0%,
        $extern-color 100%
    ); /* W3C */
    background-size: 550% 450%;
}

@mixin vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin rotate-180() {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
