.button {
	
	border: 1px solid red;
	display: inline-flex;

	button {
		width: 100%;
		margin-bottom: 0!important;
	}
}
