.wrapper.main-layout,
.wrapper.layout-dark,
.wrapper.layout-light {
    @media (min-width: 992px) {
        min-height: 100vh;
    }
}

.wrapper main {
    min-height: calc(100vh - 50px);
}

.app-sidebar {
    z-index: 101;
}

.app-sidebar .sidebar-content,
.off-canvas-sidebar .sidebar-content {
    height: calc(100vh - 90px - 60px) !important;
}

.user-block {
    padding: 20px 10px 20px 14px;
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-auto-rows: min-content;
    grid-column-gap: 10px;
    align-items: center;

    position: relative;
    z-index: 10;
}

.user-block img {
    border-radius: 50%;
}

.user-block img,
.user-block svg {
    display: block;
    margin-right: 10px;
}

.user-block-wrapper {
    display: flex;
    align-items: center;
}

.user-name {
    font-weight: 400;
    color: #ffffff;
    white-space: normal;
    line-height: 1.2rem;
}

.app-sidebar.collapsed .user-name {
    display: none;
}

.user-logout {
    transition: opacity 0.5s;

    &:hover,
    &:focus {
        opacity: 0.6;
    }
}

.app-sidebar.collapsed .user-logout {
    display: none;
}

.navbar {
    @media (min-width: 768px) {
        padding: 0;
    }
}
//скроллы

body * {
    &::-webkit-scrollbar {
        width: 7px !important;
        height: 7px !important;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey !important;
        border-radius: 10px !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3) !important;
        border-radius: 10px !important;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.4) !important;
    }
}

// tables

.layout-dark .table-custom-head thead {
    background-color: #616279;
}

.layout-light .table-custom-head thead {
    background-color: #f1f1f1;
}

.button-reset {
    background-color: transparent;
    font: inherit;
    border: none;
    display: block;
    cursor: pointer;
    text-shadow: none;
}

.total-row {
    font-weight: 500;
}

.layout-dark .with-bg-row {
    background-color: rgba(#616279, 0.8);
}

.layout-light .with-bg-row {
    background-color: rgba(#f1f1f1, 0.9);
}

.layout-dark .with-bg-row-average {
    background-color: rgba(#616279, 0.4);
}

.layout-light .with-bg-row-average {
    background-color: rgba(#f1f1f1, 0.6);
}

.layout-dark .with-bg-row-name {
    background-color: #36384b;
}

.layout-light .with-bg-row-name {
    background-color: #d1d1d1;
}

.nowrap-row {
    white-space: nowrap;
}

.layout-dark .total-row td,
.layout-dark .total-row {
    color: #ffffff !important;
}

.table-sticky {
    thead th:first-of-type {
        position: sticky;
        top: 0;
        left: -1px;
        background-color: rgb(97, 98, 121);
    }
}

.sticky-cell {
    position: sticky;
    top: 0;
    left: -1px;
    background-color: rgb(63, 65, 87);
}

.layout-dark .table-sticky {
    thead th:first-of-type {
        background-color: rgb(97, 98, 121);
    }
}

.layout-dark .sticky-cell {
    background-color: rgb(63, 65, 87);
}

.layout-light .table-sticky {
    thead th:first-of-type {
        background-color: #f1f1f1;
    }
}

.layout-light .sticky-cell {
    background-color: #ffffff;
}

// datapicker

.datapicker-input {
    width: 100%;
}

.datapicker-input input {
    display: block;
    width: 100%;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid hsl(0, 0%, 80%);
    color: hsl(0, 0%, 50%);
    background-image: url('./assets/img/calendar-icon.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: calc(100% - 10px) center;
}

.react-datepicker__triangle {
    transform: translate(0) !important;
    left: 10% !important;
}

// wrapper

.filters-wrapper {
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 10px;
    margin-bottom: 20px;

    @media (min-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1200px) {
        display: flex;

        & > div {
            width: 25%;
            margin-right: 10px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        &--bottom {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            & > div {
                width: 100%;
            }
        }
    }
}

// react-responsive-modal

.react-responsive-modal-closeButton {
    top: 35px !important;
    right: 20px !important;
}

.react-responsive-modal-modal {
    width: 90% !important;
}

// ссылки

a {
    color: inherit !important;

    &:hover,
    &:focus {
        color: inherit !important;
        text-decoration: underline;
    }
}

.app-sidebar a {
    color: #ffffff !important;

    &:hover,
    &:focus {
        color: #ffffff !important;
        text-decoration: underline;
    }
}
.task-table-result td {
    cursor: pointer;
}

// menu

.menu-collapsed main {
    margin-top: 0 !important;
}

.menu-collapsed {
    @media (min-width: 768px) {
        margin-left: 0 !important;
    }

    @media (min-width: 992px) {
        margin-left: 60px !important;
    }
}

// main

main {
    border-top: 1px solid transparent;
}

// cards statistic

.layout-light {
    .secondary-card .text-label {
        color: #212529;
    }
}

.layout-dark {
    .secondary-card * {
        color: #ffffff !important;
        stroke: #ffffff;
    }
}
