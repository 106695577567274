.input {
	//min-width: 250px;
	z-index: 10;
	width: 100%;
}

.input > div:nth-child(3) {
	position: absolute;
	z-index: 100;
}

.filter-date {
	//min-width: 250px;
	width: 100%;
	height: 36px;
	background-color: hsl(0,0%,100%);
	border-color: hsl(0,0%,80%);
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	padding: 0 8px;
	background-image: url(/static/media/calendar-icon.f0e4c44b.svg);
	background-repeat: no-repeat;
	background-size: 16px;
	background-position: calc(100% - 10px) center;
	color: gray;
}

.react-datepicker-popper {
}

.filter-date-1 {
	
}

.filter-date-2 {
	
}