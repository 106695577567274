.filter-month {
    position: relative;
    text-align: center;
    height: 36px;
    width: 100%;
}

.react-datepicker-popper {
    z-index: 2;
}

.react-datepicker-wrapper {
    
}

.react-datepicker {

    &__input-container {
        text-align: right;
        z-index: 100;
    }

}