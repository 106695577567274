.badge-toolbar {
    .badge {
        margin-right: 15px;
    }
}

.badge {
    padding: 6px 10px;
    font-size: 85%;
    font-weight: 500;
    letter-spacing: 0.3px;
}

.badge-pill {
    padding: 5px 6px 3px;
}
.badge-warning {
    color: $white;
}
