// ================================================================================================
//     File Name: coming-soon.scss
//     Description: Coming Soon pages custom scss
// ================================================================================================

/*========== Coming Soon Background Image =========*/
.comingsoonVideo .app-content .coming-soon-content {
    z-index: 99;
}
.clockCard {
    float: left;
    border-radius: 0.5rem;
}
.getting-started {
    font-size: 2.4rem;
    border-radius: 60px;
    min-width: 360px;
}
.countdownFormat {
    font-size: 16px;
    padding-bottom: 0px;
}
.socialIcon i {
    font-size: 28px;
    padding: 5px;
}
.clockFormat {
    border-radius: 60px;
}

[dir='rtl'] {
    .clockCard {
        float: right;
    }
}
