.pagination {

	ul {
		margin: 15px auto;
	}

	.active {
		//background-color: #2e2f42;
		background-color: #dddddd;
	}
	
	.disabled {
		pointer-events: none; // делаем ссылку некликабельной 
		cursor: default;  //устанавливаем курсор в виде стрелки
		color: #999; //цвет текста для нективной ссылки
	}

	.page-item:not(:first-child):not(:last-child) {
		min-width: 44px;
		text-align: center;
	}
	
}



.layout-light {}

.layout-dark {}