.h1,
.is-like-h1 {
	font-family: "Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 0.05rem;
	font-size: 1.15rem!important;
	line-height: 1.15em!important;
	margin: 0;
}

.h2 {
	margin-top: 50px;
	margin-bottom: 25px;

	font-size: 22px;
	font-weight: 500;
	line-height: 30px;
}

.h3 {
	margin: 0;

	font-size: 20px;
	font-weight: 600;
	line-height: 27px;
}
